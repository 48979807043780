export enum FeatureTypesEnum {
  FEATURE = 'Feature',

  FEATURE_TYPE_SNAPSHOT = 'SNAPSHOT',
  FEATURE_TYPE_VIEW= 'VIEW',
  FEATURE_TYPE_ELEMENT= 'ELEMENT',
  FEATURE_TYPE_POI= 'POI',
  FEATURE_TYPE_WAY= 'WAY',
  FEATURE_TYPE_ZONE= 'ZONE',
  FEATURE_TYPE_ALERT= 'ALERT',
  FEATURE_TYPE_CRASH= 'CRASH'
}
